.navbar {
    position: fixed; /* Fixed positioning */
    top: 0; /* Fix it to the top */
    left: 0; /* Align it to the left */
    right: 0; /* Align it to the right */
    display: flex;
    justify-content: space-between;
    background-color: #282c34;
    padding: 10px 20px; /* Adjust padding as needed */
    width: 100%; /* Make the tab bar stretch across the width of the viewport */
    box-sizing: border-box; /* Include padding and border in the width calculation */
}


.navbar .nav-link {
    color: white;
    cursor: pointer;
}
    
.navbar a {
    color: white;
    text-decoration: none;
    padding: 0.5rem;
    font-size: .9rem;
}
    
.navbar a:hover {
    text-decoration: underline;
    color: white
}

/* RESETTING THE NAV ORIENTATION */ 
@media screen and (min-width: 750px) {
    .navbar {
      top: 0;
      bottom: auto;
    }
 }
  

/* IPAD BREAKPOINT */
@media screen and (min-width: 750px) and (max-width: 1023px ) and (orientation: portrait) {
	

    .navbar a {
        font-size: 2rem;
    }
    
    .navbar h1 {
        font-size: 2rem;
    }
}


 /* MEDIUM BREAKPOINT */
@media screen and (min-width: 0px) and (max-width: 1025px) and (orientation:landscape){
    .navbar a {
        font-size: 2rem;
    }
    
    .navbar h1 {
        font-size: 2rem;
    }

}

/* LARGE BREAKPOINT */
@media screen and (min-width: 1022px) and (orientation: landscape){
    .navbar a {
        font-size: 2rem;
    }
    
    .navbar h1 {
        font-size: 2rem;
    }
}