.Resume-header {
  background-color: #FCF5E5;
  min-height: 100vh;
  display: flex;
  flex-direction: column;  
  align-items: center;
  justify-content: center;
  font-size: calc(8px + 2vmin);
  color: #282c34;
  padding: 20px;
}

.resume-image {
  width: 100%; /* Make the image take up the full width of its container */
  height: auto; /* Maintain the aspect ratio of the image */
  max-width: 800px; /* Limit the max width for larger screens */
  margin: 20px 0; /* Add some vertical margin for spacing */
  border-radius: 5px; /* Optional: add rounded corners */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Optional: add a subtle shadow for depth */
}

.resume-header {
  text-align: center; /* Center text within the header */
}

.resume-content {
  margin: 20px;
  width: 100%;
  max-width: 800px; /* Limit width for better readability */
}

.resume-section {
  margin-bottom: 20px; /* Add spacing between sections */
}

.resume-image {
  width: 100%; /* Make the image responsive */
  height: auto; /* Maintain aspect ratio */
  max-width: 800px; /* Limit the max width */
  margin: 20px 0; /* Add vertical margin */
  border-radius: 5px; /* Optional rounded corners */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Optional shadow */
}

h1, h2 {
  font-weight: bold;
}

ul {
  list-style-type: none; /* Clean list style */
  padding: 0; /* Remove padding */
}

a {
  color: #0066cc; /* Link color */
}


.resume-header {
  text-align: center; /* Center text within the header */
}

.resume-content {
  margin: 20px;
  width: 100%;
  max-width: 800px; /* Limit width for better readability */
}



.resume-section {
  margin-bottom: 20px; /* Add spacing between sections */
}

h1, h2 {
  font-weight: bold;
}

ul {
  list-style-type: none; /* Clean list style */
  padding: 0; /* Remove padding */
}

a {
  color: #0066cc; /* Link color */
}


.resume-download {
  margin-top: 20px; /* Spacing above the button */
}

.download-button {
  display: inline-block;
  padding: 10px 20px; /* Padding for the button */
  background-color: #0066cc; /* Button color */
  color: white; /* Text color */
  text-decoration: none; /* Remove underline */
  border-radius: 5px; /* Rounded corners */
  transition: background-color 0.3s; /* Smooth transition */
}

.download-button:hover {
  background-color: #004999; /* Darker shade on hover */
}

