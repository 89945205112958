.Kennen {
 background-color: #FCF5E5;
 min-height: 00vh;
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: center;
 font-size: calc(8px + 2vmin);
 color: rgba(83, 42, 42, 1); /* Corrected color format */
 padding: 60px; /* Add some padding around */
}

.image-grid {
 display: grid; /* Use grid layout */
 grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Responsive grid with larger minimum width */
 gap: 15px; /* Space between images */
 width: 100%; /* Full width of the parent */
 max-width: 1200px; /* Optional: limit max width for larger screens */
}

.thumbnail {
 width: 100%; /* Make images fill the grid cell */
 height: 400px; /* Set a uniform height for all images */
 object-fit: cover; /* Cover the entire area without distortion */
 border-radius: 8px; /* Rounded corners */
 transition: transform 0.3s; /* Smooth hover effect */
}

.thumbnail:hover {
 transform: scale(1.30); /* Zoom effect on hover */
}
