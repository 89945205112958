.Covers {
 background-color: #FCF5E5;
 min-height: 100vh;
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: center;
 font-size: calc(8px + 2vmin);
 color: rgba(83, 42, 42, 1); /* Corrected color format */
 padding: 20px; /* Add some padding around */
}