.Main-logo {
    height: 80vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
.Main-logo {
    animation: App-logo-spin infinite 20s linear;
}
}
  
.Main-header {
  margin-top: 50px;

  background-color: #FCF5E5;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Roboto', sans-serif; /* Set font to Roboto */
  font-size: calc(28px + 2vmin); /* Increased font size for better readability */
  color: #5C4A4A; /* Updated text color */
  text-align: center; /* Center the text */
  padding: 20px; /* Add padding for better spacing */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Subtle shadow effect for depth */
  border-radius: 10px; /* Rounded corners for a softer look */
}

.Main-header h1 {
  font-size: calc(48px + 2vmin); /* Larger font size for headings */
  margin-bottom: 10px; /* Spacing between heading and subheading */
  font-weight: 700; /* Bold heading using Roboto */
}

.Main-header p {
  font-size: calc(24px + 1vmin); /* Larger responsive paragraph font size */
  margin: 5px 0; /* Spacing between paragraphs */
}

/* Optional: Add media queries for better responsiveness on smaller screens */
@media (max-width: 768px) {
  .Main-header h1 {
    font-size: 36px; /* Adjust heading size for smaller screens */
  }
  
  .Main-header p {
    font-size: 18px; /* Adjust paragraph size for smaller screens */
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}