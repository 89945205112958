.Covers {
    background-color: #FCF5E5;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(8px + 2vmin);
    color: rgba(83, 42, 42, 1); /* Corrected color format */
    padding: 20px; /* Add some padding around */
}

.image-grid {
    display: grid; /* Use grid layout */
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Responsive grid */
    gap: 15px; /* Space between images */
    width: 100%; /* Full width of the parent */
    max-width: 1200px; /* Optional: limit max width for larger screens */
}

.thumbnail {
    width: 100%; /* Make images fill the grid cell */
    height: auto; /* Maintain aspect ratio */
    border-radius: 8px; /* Rounded corners */
    transition: transform 0.3s; /* Smooth hover effect */
}

.thumbnail:hover {
    transform: scale(1.05); /* Zoom effect on hover */
}

.contact-content {
    margin-top: 50px; 
    margin-left: 50px;
}
