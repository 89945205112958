.Contact-header {
 background-color: #FCF5E5;
 min-height: 100vh;
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: center;
 font-size: calc(8px + 2vmin);
 color: #282c34(83, 42, 42);
 }
 
 .contact-content {
  margin-top: 50px; 
  margin-left: 50px;
 }